<!-- not using?  -->
<!-- identify where is using this component -->
<template>
    <div class="container">
        <div class="row">
            <div class="col-12 py-4">
                <div class="p-fluid countainer-fluid">
                    <Button 
                        class="p-button-raised p-button-rounded my-3 p-button-danger" 
                        icon="pi pi-arrow-left"
                        @click="goBack" 
                    />
                    <h3>EDIT PROGRAMME</h3>
                    <Divider align="left">
                        <div class="inline-flex align-items-center">
                            <span class="p-tag">Information</span>
                        </div>
                    </Divider>

                    <div class="row">
                        <div class="col-12">
                            <div class="field my-3">
                                <label for="programName">Programme name</label>
                                <InputText 
                                    id="name" 
                                    v-model.trim="newProgramForm.name"
                                    @blur="v$.newProgramForm.name.$touch" 
                                    required="true" 
                                    autofocus
                                    :class="{'p-invalid': !newProgramForm.name}" 
                                />
                                <small class="p-error" v-if="!newProgramForm.name">
                                    Name is required
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="field my-3">
                                <label for="programName">Topic</label>
                                <Dropdown 
                                    v-model="newProgramForm.programTopic" 
                                    :options="topics" 
                                    optionLabel="name"
                                    optionValue="topicId" 
                                    placeholder="Select topic" 
                                    style="width: 70%" 
                                />
                                <small class="p-error" v-if="!newProgramForm.programTopic">
                                    Topic is required
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            <div class="field my-3">
                                <label for="date">Programme Date</label>
                                <Calendar v-model="newProgramForm.programDate" dateFormat="mm/dd/yy" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            <div class="field my-3">
                                <label for="country">Country</label>
                                <template v-if="newProgramForm.programTopic">
                                    <div v-for="(segmentation, index) in segmentations"
                                        :key="segmentation.segmentationId" class="my-3">
                                        <div v-if="segmentation.isRegional">
                                            <label for="countryRegionName"
                                                style="margin-left: 2rem;">{{segmentation.name}}</label>
                                            <Dropdown 
                                                v-model="newProgramForm.country[index]"
                                                :options="getCountries(index)" 
                                                optionLabel="name"
                                                optionValue="countryId" 
                                                :filter="true" 
                                                placeholder="Select Country"
                                                style="width: 70%" 
                                                @change="setSegmItem(index ,$event )" 
                                            />
                                        </div>
                                    </div>

                                </template>
                                <template v-else>
                                    <small class="p-error">
                                        Topic is not selected
                                    </small>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            <div class="field my-3">
                                <template v-if="newProgramForm.programTopic">
                                    <div v-for="(segmentation, index) in segmentations"
                                        :key="segmentation.segmentationId" 
                                        class="my-3"
                                    >
                                        <div v-if="!segmentation.isRegional">
                                            <label for="country">Segmentations -{{segmentation.name}}</label>
                                            <Dropdown 
                                                v-model="newProgramForm.segmentationItems[index]"
                                                :options="segmentation.segmentationItems" 
                                                optionLabel="name"
                                                optionValue="segmentationItemId" 
                                                placeholder="Select Segmentation Items"
                                                style="width: 70%" />
                                        </div>
                                    </div>

                                </template>
                                <template v-else>
                                    <label for="country">Segmentations</label>
                                    <small class="p-error">
                                        Topic is not selected
                                    </small>
                                </template>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12">
                            <div class="field my-3">
                                <label for="notes">Notes</label>
                                <Textarea 
                                    id="notes" 
                                    v-model.trim="newProgramForm.notes" 
                                    required="true" 
                                    rows="5"
                                    cols="30" 
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 p-4">
                <div class="d-flex justify-content-end px-3">
                    <Button 
                        label="Save" 
                        icon="pi pi-check" 
                        class="p-button-success ml-3"
                        :loading="createProgramIsLoading"
                        :disabled="this.v$.newProgramForm.$invalid || createProgramIsLoading"
                        @click="editProgram()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
    name: 'NewProgram',
    components: {
        Button,
        Divider,
        InputText,
        Calendar,
        Dropdown,
        Textarea,
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading: true,
            createProgramIsLoading: false,
            documentProgramId: null,
            newProgramForm: {
                name: null,
                programDate: null,
                country: [],
                region: [],
                notes: null,
                segmentationItems: [],
                programTopic: null,
                documentProgramTopicId: null,
            },
        }
    },
    validations() {
        return {
            newProgramForm: {
                name: { required },
            }
        }
    },
    async mounted() {
        await this.getProgramById(this.$route.params.programId)
        await this.getTopics();
        await this.getEditForm(this.program)
    },
    computed: {
        program() {
            return this.$store.getters.obtainProgram;
        },
        topics() {
            return this.$store.getters.obtainTopics;
        },
        segmentations() {
            return this.$store.getters.obtainSegmentations;
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async getProgramById(programId) {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainProgramById', programId);
                this.loading = false;
            }
            catch (error) {
                toast.error(error.message);
                this.loading = false;
            }
        },
        async getTopics() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainTopics');
                this.loading = false;
            } catch (error) {
                toast.error(error.message);
                this.loading = false
            }
        },
        async getSegmentations(topicId) {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainSegmentations', topicId)
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        getCountries(index) {
            let countriesList = []
            // countriesList.push( si.region.countries )
            this.segmentations.filter(se => se.isRegional)[index].segmentationItems.map(si => si.region.regionCountries.map(rc => countriesList.push( rc.country )) )
            return countriesList.flat()
        },
        setSegmItem(index, event) {
            const segmentationItemId = this.segmentations.filter(se => se.isRegional)[index].segmentationItems.filter(si => si.region.countries.some(c => c.countryId === event.value)).map(filtered => filtered.segmentationItemId)
            const regionId = this.segmentations.filter(se => se.isRegional)[index].segmentationItems.filter(si => si.region.countries.some(c => c.countryId === event.value)).map(filtered => filtered.region.regionId)
            this.newProgramForm.region[index] = regionId[0]
            this.newProgramForm.segmentationItems[index] = segmentationItemId[0]

        },
        async getEditForm(newVal) {
            this.documentProgramId = newVal.documentProgramId
            this.newProgramForm.name = newVal.name
            this.newProgramForm.programTopic = newVal.documentProgramTopics[0].topicId
            await this.getSegmentations(newVal.documentProgramTopics[0].topicId)
            newVal.documentProgramTopics[0].programDate = new Date(newVal.documentProgramTopics[0].programDate);
            this.newProgramForm.programDate = newVal.documentProgramTopics[0].programDate
            this.newProgramForm.notes = newVal.documentProgramTopics[0].notes
            this.newProgramForm.documentProgramTopicId = newVal.documentProgramTopics[0].documentProgramTopicId
            const segmRegion1 = newVal.documentProgramTopics[0].docProgramTopicSegItems.filter(segItem => segItem.regionCountry)[0]
            this.newProgramForm.region.push(segmRegion1.regionCountry.regionId)
            this.newProgramForm.country.push(segmRegion1.regionCountry.countryId)
            newVal.documentProgramTopics[0].docProgramTopicSegItems.map(docProgramTopicSegItem => this.findIndex(docProgramTopicSegItem.segmentationItemId))
        },
        findIndex(segmentationItemId){
            // console.log(segmentationItemId)
            // console.log(this.segmentations)
            const index = this.segmentations.findIndex(segmentation => segmentation.segmentationItems.some(segmentationItem => segmentationItem.segmentationItemId === segmentationItemId ))
            // console.log(index)
            this.newProgramForm.segmentationItems[index] = segmentationItemId
        },
        // for multiples topics
        // addTopic() {
        //     this.programTopics.push({
        //         topicId: '',
        //     });
        // },
        // removeTopic(topicId) {
        //     this.programTopics = this.programTopics.filter(
        //         t => t.topicId != topicId
        //     );
        // },
        async editProgram() {
            try {
                if (!this.v$.newProgramForm.$invalid) {
                    this.createProgramIsLoading = true;

                    // console.log(this.newProgramForm.segmentationItems)
                    // console.log(this.newProgramForm.country)
                    let programForm = {
                        'name': this.newProgramForm.name,
                        'documentProgramTopics': [],
                        'documentProgramId':this.documentProgramId,
                    }
                    let documentProgramTopics = {
                        'documentProgramTopicId': this.newProgramForm.documentProgramTopicId,
                        'topicId': this.newProgramForm.programTopic,
                        'programDate': this.newProgramForm.programDate,
                        'notes': this.newProgramForm.notes,
                        'docProgramTopicSegItems': []
                    }

                    let segmentationItems = []
                    this.newProgramForm.segmentationItems.map((item, index) => {
                        const regionCountry = {
                            'regionId': this.newProgramForm.region[index],
                            'countryId': this.newProgramForm.country[index],
                        }
                        if (!this.newProgramForm.country[index]) {
                            const segmentationItems1 = {
                                'segmentationItemId': item,
                                'regionCountry': null,
                            }
                            segmentationItems[index] = segmentationItems1
                        }
                        else {
                            const segmentationItems1 = {
                                'segmentationItemId': item,
                                'regionCountry': regionCountry,
                            }
                            segmentationItems[index] = segmentationItems1
                        }
                    }
                    );
                    documentProgramTopics.docProgramTopicSegItems = segmentationItems
                    programForm.documentProgramTopics.push(documentProgramTopics)
                    // programForm.documentProgramTopics = documentProgramTopics
                    // console.log(this.documentProgramId)
                    console.log(JSON.stringify(programForm))
                    await this.$store.dispatch('modifyProgram', programForm);
                    this.createProgramIsLoading = false;
                    toast.success('Successfully created.');
                    this.goBack();
                }
            } catch (error) {
                this.createProgramIsLoading = false;
                toast.error(error.message);
            }
        }
    },
    watch: {
        'newProgramForm.programTopic': {
            async handler(newVal) {
                // console.log(newVal)
                await this.getSegmentations(newVal)
            },
            deep: true,
        },
    }
}

</script>
<style>

</style>